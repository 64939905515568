import { createAndSetupAxios } from "@/helpers/setupAxios";
import type {
  AnswerQuestionOptions,
  AnswerQuestionResponseData,
  ChunkSizesResponseData,
  DocumentResearchData,
  DocumentResearchSummary,
  ExplorerSubTopicsResponseData,
  ExplorerTopicsResponseData,
  FeedbackType,
  GetChapterContextKeywordsRequest,
  ExtractChapterSubTopicOptionsRequest,
  KeywordSimilarity,
  SearchPool,
  SemanticSearchFile,
  TenderDataChunkResult,
  TenderDataIndicesDocument,
  TenderDataIndicesResponseData,
  TenderDataResult,
  TenderDataResultDocument,
  TenderDatasResponseData,
  TagsResponseData,
  TextCompletionRequest,
  TenderBookmark,
  ChapterSubtopicsSuggestionsRequest,
  SuggestNewChapterTopicOptionsRequest,
  GenerateChapterCompletionDraftRequest,
  RefineChapterCompletionDraftRequest,
  SetReferenceDocumentsRequest,
  GetReferenceDocumentsRequest,
  SuggestChapterSubtopicsRequest,
  SuggestNewChapterHeadlinesRequest,
  SuggestNewChapterSubtopicOptionsRequest,
  GenerateNewChapterDraftRequest,
  GetAssistantStatus,
} from "@/types/assistant";
import { KeywordMinimalType } from "@/types/criterion";
import { HighlightBox } from "@/types/highlight";
import type { Facet } from "@/types/shoppingsearch";
import { AxiosResponse } from "axios";

const $axios = createAndSetupAxios(import.meta.env.VITE_ASSISTANT_ROUTE);

/** @see https://dev.govradar.net/api/service_docs/ai-assistant/redoc */
class AssistantService {
  /** Fetches available chunk sizes for `getTags` */
  async getChunkSizes(): Promise<ChunkSizesResponseData> {
    const response = await $axios.get("/explorer/chunk_sizes/");

    return response.data;
  }

  /** Fetches tag chunks by size, available chunk sizes are provided by `getChunkSizes` */
  async getTags(
    chunkSize: number,
    limit: number,
    topCutOff: number,
    keywords: KeywordMinimalType[],
  ): Promise<TagsResponseData> {
    const response = await $axios.post("/explorer/tags/", {
      chunk_size: chunkSize,
      limit,
      top_cut_off: topCutOff,
      keywords,
    });

    return response.data;
  }

  async getTopics(
    searchPool: SearchPool,
    searchKeywords: string[] = [],
    documents: TenderDataIndicesDocument[] = [],
    limit = 40,
  ): Promise<ExplorerTopicsResponseData> {
    try {
      const response = await $axios.post(`/explorer/topics/${searchPool}/`, {
        limit,
        documents,
        search_keywords: searchKeywords,
      });

      return response.data;
    } catch {
      return {
        process_id: "",
        topic_results: [],
      };
    }
  }

  async getSubTopics(
    searchPool: SearchPool,
    processId: ExplorerTopicsResponseData["process_id"],
    topicKeywords: { keyword: string; level: number }[] = [],
    searchKeywords: string[] = [],
    documents: TenderDataIndicesDocument[] = [],
  ): Promise<ExplorerSubTopicsResponseData> {
    try {
      const response = await $axios.post(
        `/explorer/sub_topics/${searchPool}/`,
        {
          process_id: processId,
          documents,
          topic_keywords: topicKeywords,
          search_keywords: searchKeywords,
        },
      );

      return response.data;
    } catch {
      return [];
    }
  }

  async getTopicsNew(
    searchPool: SearchPool,
    query?: string,
    documents: TenderDataIndicesDocument[] = [],
    limit = 40,
  ): Promise<ExplorerTopicsResponseData> {
    try {
      const response = await $axios.post(`/explorer/topics/${searchPool}/`, {
        limit,
        documents,
        query,
      });

      return response.data;
    } catch {
      return {
        process_id: "",
        topic_results: [],
      };
    }
  }

  async getSubTopicsNew(
    searchPool: SearchPool,
    processId: ExplorerTopicsResponseData["process_id"],
    topicKeywords: { keyword: string; level: number }[] = [],
    documents: TenderDataIndicesDocument[] = [],
  ): Promise<ExplorerSubTopicsResponseData> {
    try {
      const response = await $axios.post(
        `/explorer/sub_topics/${searchPool}/`,
        {
          process_id: processId,
          documents,
          topic_keywords: topicKeywords,
        },
      );

      return response.data;
    } catch {
      return [];
    }
  }

  async getChunks(
    searchPool: SearchPool,
    documents: TenderDataIndicesDocument[] = [],
    topicKeywords: string[] = [],
    searchKeywords: string[] = [],
  ): Promise<DocumentResearchData> {
    const response = await $axios.post(`/explorer/chunks/${searchPool}/`, {
      documents,
      topic_keywords: topicKeywords,
      search_keywords: searchKeywords,
    });

    return response.data;
  }

  /** Finds tender datas by keywords or questions */
  async findTenderDatas(
    searchPool: SearchPool,
    keywords: string[],
    facets: Facet[] = [],
    limit = 20,
    offset = 0,
    sortBy: "all_keywords" | string = "all_keywords",
    modeMapping: Record<string, KeywordSimilarity> = {},
  ): Promise<TenderDatasResponseData> {
    const response = await $axios.post(`/lv_finder_augmented/${searchPool}/`, {
      keywords,
      facets,
      limit,
      offset,
      keyword_sort_by: sortBy,
      keyword_search_mode_mapping: modeMapping,
    });

    return response.data;
  }

  /** New Tenders search, now with query instead of keywords */
  async findTenderDatasByQuery(
    searchPool: SearchPool,
    query: string,
    facets: Facet[] = [],
    limit = 20,
    offset = 0,
    includeIndices = false,
  ): Promise<TenderDatasResponseData> {
    const response = await $axios.post(
      `/tenders/search/?search_pool=${searchPool}&return_indices=${includeIndices}`,
      {
        query,
        facets,
        limit,
        offset,
      },
    );

    return response.data;
  }

  /** Filter tenders results by question */
  async filterTenderDatasByQuestion(
    searchPool: SearchPool,
    searchId: string,
    query: string,
    question: string,
    facets: Facet[] = [],
    limit = 20,
    offset = 0,
  ): Promise<TenderDatasResponseData> {
    const response = await $axios.post(
      `/tenders/question_filter/?search_pool=${searchPool}`,
      {
        search_id: searchId,
        query,
        question,
        facets,
        limit,
        offset,
      },
    );

    return response.data;
  }

  /** Filter reference documents by question */
  async filterReferenceDocumentsByQuestion(
    tenderDataId: string,
    question: string,
    facets: Facet[] = [],
    limit = 20,
    offset = 0,
  ): Promise<TenderDatasResponseData> {
    const response = await $axios.post(
      `/document_assistant/reference_documents/question_filter`,
      {
        service_description_id: tenderDataId,
        question,
        facets,
        limit,
        offset,
      },
    );

    return response.data;
  }

  /** Filter tenders results by topic */
  async filterTenderDatasByTopic(
    searchPool: SearchPool,
    searchId: string,
    query: string,
    topicKeywords: string[],
    facets: Facet[] = [],
    limit = 20,
    offset = 0,
  ): Promise<TenderDatasResponseData> {
    const response = await $axios.post(
      `/tenders/topic_filter/?search_pool=${searchPool}`,
      {
        search_id: searchId,
        query,
        topic_keywords: topicKeywords,
        facets,
        limit,
        offset,
      },
    );

    return response.data;
  }

  /** Filter reference documents by topic */
  async filterReferenceDocumentsByTopic(
    tenderDataId: string,
    topicKeywords: string[],
    facets: Facet[] = [],
    limit = 20,
    offset = 0,
  ): Promise<TenderDatasResponseData> {
    const response = await $axios.post(
      `/document_assistant/reference_documents/topic_filter`,
      {
        service_description_id: tenderDataId,
        topic_keywords: topicKeywords,
        facets,
        limit,
        offset,
      },
    );

    return response.data;
  }

  /** Finds indices of tender datas by keywords or questions */
  async findTenderDataIndices(
    searchPool: SearchPool,
    keywords: string[],
    facets: Facet[] = [],
    sortBy: "all_keywords" | string = "all_keywords",
    modeMapping: Record<string, KeywordSimilarity> = {},
    logicalOperator: "and" | "or" = "and",
  ): Promise<TenderDataIndicesResponseData> {
    const response = await $axios.post(
      `/lv_finder_augmented/${searchPool}/indices/`,
      {
        keywords,
        facets,
        keyword_sort_by: sortBy,
        keyword_search_mode_mapping: modeMapping,
        keyword_logical_operator: logicalOperator,
      },
    );

    return response.data;
  }

  /** Answers natural language questions about tenders with natural language responses, including their sources */
  async answerTenderQuestion(
    searchPool: SearchPool,
    chatId: string,
    question: string,
    documentIds: TenderDataResultDocument["document_id"][],
    options: Partial<AnswerQuestionOptions> = {},
  ): Promise<AnswerQuestionResponseData> {
    const optionDefaults: AnswerQuestionOptions = {
      answerLength: "normal",
      answeringMode: "gpt4",
      questionType: "new_question",
    };

    const combinedOptions: AnswerQuestionOptions = Object.assign(
      optionDefaults,
      options,
    );

    const response = await $axios.post(
      `/chat/${chatId}/answer_tender_question/${searchPool}`,
      {
        question,
        answer_length: combinedOptions.answerLength,
        document_ids: documentIds,
      },
    );

    return response.data;
  }

  /** Answers generic natural language questions using ChatGPT */
  async answerGenericQuestionIncludingChatHistory(
    chatId: string,
    question: string,
  ): Promise<AnswerQuestionResponseData> {
    const response = await $axios.post(
      `/chat/${chatId}/answer_generic_question`,
      {
        question,
        include_chat_history: true,
      },
    );

    return response.data;
  }

  /** Report an answer to a specific question as helpful (`"upvote"`) or unhelpful (`"downvote"`) */
  async rateAnswer(
    chatId: string,
    questionId: string,
    rating: "upvote" | "downvote",
    feedbackType?: FeedbackType,
    comment?: string,
  ): Promise<unknown> {
    const response = await $axios.patch(
      `/chat/${chatId}/questions/${questionId}/rate_answer`,
      {
        rating,
        type_of_feedback: feedbackType,
        rating_comment: comment,
      },
    );

    return response.data;
  }

  async researchDocuments(
    searchPool: SearchPool,
    question: string,
    documentIds: string[] = [],
  ): Promise<DocumentResearchData> {
    const response = await $axios.post(
      `/document_detail_research/${searchPool}/`,
      {
        question,
        document_ids: documentIds,
      },
    );

    return response.data;
  }

  async summarizeParagraphs(
    searchPool: SearchPool,
    question: string,
    paragraphContents: string[],
  ): Promise<DocumentResearchSummary> {
    const response = await $axios.post(
      `/document_detail_research/summary/${searchPool}/`,
      {
        question,
        paragraph_contents: paragraphContents,
      },
    );

    return response.data;
  }

  async summarizeParagraphsStreamed(
    searchPool: SearchPool,
    question: string,
    paragraphContents: string[],
    cb: (progress: string) => void,
  ): Promise<DocumentResearchSummary> {
    const response = await $axios.post(
      `/document_detail_research/summary/${searchPool}/stream/`,
      {
        question,
        paragraph_contents: paragraphContents,
      },
      {
        responseType: "text",
        onDownloadProgress: ({ event }) => {
          cb(event.target.responseText ?? event.target.response);
        },
      },
    );

    return {
      summary: response.data,
    };
  }

  /** Fetch initial set of Facets, updated facets are returned with Finder responses */
  async getFacets(searchPool: SearchPool): Promise<Facet[]> {
    const response = await $axios.get(`/facets/${searchPool}/`);

    return response.data;
  }

  /** Fetch hashtag suggestions based on the provided input `query` */
  async getHashtagSuggestions(query: string): Promise<string[]> {
    const response = await $axios.get("/keywords/generic/", {
      params: { query },
    });

    return response.data;
  }

  /** Fetch keyword suggestions based on the provided input `query` */
  async getKeywordSuggestions(query: string): Promise<string[]> {
    const response = await $axios.get("/keywords/detailed/", {
      params: { query },
    });

    return response.data;
  }

  /** Fetch query suggestions based on the provided input `query` */
  async getSearchQuerySuggestions(query: string): Promise<string[]> {
    const response = await $axios.get("/tenders/search/autocomplete/", {
      params: { query },
    });

    return response.data;
  }

  async getDocumentBlob(
    document_id: number | string,
  ): Promise<AxiosResponse<Blob>> {
    return $axios.get("/download_document_blob/", {
      params: {
        document_id,
      },
      responseType: "blob",
    });
  }

  async getTenderBlob(tender_id: string): Promise<AxiosResponse<Blob>> {
    return $axios.get("/download_tender_blob/", {
      params: {
        tender_id,
      },
      responseType: "blob",
    });
  }

  async getTenderHitsBlob(
    tender_id: string,
    document_ids: string[],
  ): Promise<AxiosResponse<Blob>> {
    return $axios.get("/download_tender_hits_blob/", {
      params: {
        tender_id,
        document_ids: document_ids.join(","),
      },
      responseType: "blob",
    });
  }

  async getTenderFiles(tender_id: string): Promise<SemanticSearchFile[]> {
    return $axios
      .get("/get_tender_files/", {
        params: {
          tender_id,
        },
      })
      .then((response) => response.data.tender_files);
  }

  async getParagraph(
    document_id: string,
    chunk_position: number,
  ): Promise<TenderDataChunkResult> {
    const response = await $axios.post("/get_next_paragraph/", {
      document_id: document_id,
      doc_position: chunk_position,
    });

    return response.data;
  }

  async hasPrivateSearchPool(): Promise<boolean> {
    return $axios
      .get("/search_pool/org_has_private_data_pool/")
      .then((response) => response.data);
  }

  async extendText(text: string): Promise<string> {
    return $axios
      .post("/text_modify/extend/", { text: text })
      .then((response) => response.data.answer);
  }

  async shortenText(text: string): Promise<string> {
    return $axios
      .post("/text_modify/shorten/", { text: text })
      .then((response) => response.data.answer);
  }

  async summarizeText(text: string): Promise<string> {
    return $axios
      .post("/text_modify/summary/", { text: text })
      .then((response) => response.data.answer);
  }

  async professionalizeText(text: string): Promise<string> {
    return $axios
      .post("/text_modify/writingstyle/", { text: text })
      .then((response) => response.data.answer);
  }

  async bulletPointText(text: string): Promise<string> {
    return $axios
      .post("/text_modify/to_bullet_points/", { text: text })
      .then((response) => response.data.answer);
  }

  async flowingText(text: string): Promise<string> {
    return $axios
      .post("/text_modify/continuous/", { text: text })
      .then((response) => response.data.answer);
  }

  async getTextCompletionCategories(): Promise<string[]> {
    return $axios
      .get("/document_assistant/subjects_of_performance/")
      .then((response) => response.data);
  }

  async getContextKeywords(
    request: GetChapterContextKeywordsRequest["payload"],
  ): Promise<GetChapterContextKeywordsRequest["response"]> {
    return $axios
      .post("/document_assistant/context_level_options/", request)
      .then(
        (response) =>
          response.data as GetChapterContextKeywordsRequest["response"],
      )
      .then((data) => ({
        process_id: data.process_id,
        context_options: data.context_options.sort((a, b) => a.level - b.level),
      }));
  }

  async extractChapterSubTopicOptions(
    request: ExtractChapterSubTopicOptionsRequest["payload"],
  ): Promise<ExtractChapterSubTopicOptionsRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/chapter_completion/sub_topic_options/",
      request,
    );

    return response.data;
  }

  async generateChapterCompletionDraft(
    request: GenerateChapterCompletionDraftRequest["payload"],
  ): Promise<GenerateChapterCompletionDraftRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/chapter_completion/draft_generation/",
      request,
    );
    return response.data;
  }

  async refineChapterCompletionDraft(
    request: RefineChapterCompletionDraftRequest["payload"],
  ): Promise<RefineChapterCompletionDraftRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/draft_refinement/",
      request,
    );
    return response.data;
  }

  async getDocumentTextCompletion(
    request: TextCompletionRequest,
    onUpdate: (response: string, done: boolean) => void,
  ): Promise<void> {
    const response = await $axios.post(
      "/document_assistant/text_completion/",
      request,
      {
        responseType: "text",
        onDownloadProgress: ({ event }) => {
          onUpdate(event.target.responseText ?? event.target.response, false);
        },
      },
    );

    onUpdate(response.data, true);
  }

  async getDocumentChapterSubtopicSuggestions(
    request: ChapterSubtopicsSuggestionsRequest["payload"],
  ): Promise<ChapterSubtopicsSuggestionsRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/v2/text_completion/sub_topic_suggestions/",
      request,
    );

    return response.data;
  }

  /** New Chapter Endpoints - Start */

  async suggestNewChapterTopicOptions(
    request: SuggestNewChapterTopicOptionsRequest["payload"],
  ): Promise<SuggestNewChapterTopicOptionsRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/new_chapter/topic_options/",
      request,
    );

    return response.data;
  }

  async suggestNewChapterSubtopicOptions(
    request: SuggestNewChapterSubtopicOptionsRequest["payload"],
  ): Promise<SuggestNewChapterSubtopicOptionsRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/new_chapter/sub_topic_options/",
      request,
    );

    return response.data;
  }

  async generateNewChapterDraft(
    request: GenerateNewChapterDraftRequest["payload"],
  ): Promise<GenerateNewChapterDraftRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/new_chapter/draft_generation/",
      request,
    );

    return response.data;
  }

  /** New Chapter Endpoints - End */

  /** Chapter Headings Endpoints - Start */
  async suggestChapterSubtopics(
    request: SuggestChapterSubtopicsRequest["payload"],
  ): Promise<SuggestChapterSubtopicsRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/chapter_completion/headline_suggestions/",
      request,
    );

    return response.data;
  }

  async suggestNewChapterHeadlines(
    request: SuggestNewChapterHeadlinesRequest["payload"],
  ): Promise<SuggestNewChapterHeadlinesRequest["response"]> {
    const response = await $axios.post(
      "/document_assistant/new_chapter/headline_suggestions/",
      request,
    );

    return response.data;
  }
  /** Chapter Headings Endpoints - End */

  async getReferenceDocuments(
    request: GetReferenceDocumentsRequest["payload"],
    includeIndices = false,
  ): Promise<GetReferenceDocumentsRequest["response"]> {
    return $axios
      .post(
        `/document_assistant/reference_documents/?return_indices=${includeIndices}`,
        request,
      )
      .then((response) => response.data);
  }

  async setReferenceDocuments(
    request: SetReferenceDocumentsRequest["payload"],
  ): Promise<SetReferenceDocumentsRequest["response"]> {
    return $axios
      .post("/document_assistant/references/", request)
      .then((response) => response.data);
  }

  async getAssistantStatus(
    request: GetAssistantStatus["payload"],
  ): Promise<GetAssistantStatus["response"]> {
    return $axios
      .get(
        `/document_assistant/tender_status/?tender_id=${request.tender_id}&search_pool=${request.search_pool}`,
      )
      .then((response) => response.data);
  }

  /** Bookmarks Endpoints */
  async getBookmarks(): Promise<TenderBookmark[]> {
    return $axios.get("/bookmarks/tender/").then((response) => response.data);
  }

  async addTenderBookmark(
    tenderId: string,
    name?: string,
    addedByUser = true,
  ): Promise<TenderBookmark> {
    return $axios
      .post("/bookmarks/tender/", {
        tender_id: tenderId,
        added_by_user: addedByUser,
        ...(name && { name }),
      })
      .then((response) => response.data);
  }

  async updateTenderBookmark(
    tenderBookmarkId: string,
    name: string,
  ): Promise<TenderBookmark> {
    return $axios
      .patch(`/bookmarks/tender/${tenderBookmarkId}/`, {
        name: name ?? "",
      })
      .then((response) => response.data);
  }

  async deleteTenderBookmark(bookmarkId: string) {
    return $axios
      .delete(`/bookmarks/tender/${bookmarkId}`)
      .then((response) => response.data);
  }

  async getTendersByIds(
    tenderIds: string[],
    searchPool: SearchPool,
  ): Promise<TenderDataResult[]> {
    return $axios
      .post("/tenders_by_id/", {
        tender_ids: tenderIds,
        search_pool: searchPool,
      })
      .then((response) => response.data);
  }

  async addDocumentBookmark(
    tenderBookmarkId: string,
    documentId: string,
    name?: string,
    addedByUser = true,
  ): Promise<TenderBookmark> {
    return $axios
      .post(`/bookmarks/${tenderBookmarkId}/document/`, {
        document_id: documentId,
        added_by_user: addedByUser,
        ...(name && { name }),
      })
      .then((response) => response.data);
  }

  async updateDocumentBookmark(
    tenderBookmarkId: string,
    documentBookmarkId: string,
    name: string,
  ): Promise<TenderBookmark> {
    return $axios
      .patch(`/bookmarks/${tenderBookmarkId}/document/${documentBookmarkId}/`, {
        name: name ?? "",
      })
      .then((response) => response.data);
  }

  async deleteDocumentBookmark(
    tenderBookmarkId: string,
    documentBookmarkId: string,
  ) {
    return $axios
      .delete(`/bookmarks/${tenderBookmarkId}/document/${documentBookmarkId}/`)
      .then((response) => response.data);
  }

  async addTextBookmark(
    tenderBookmarkId: string,
    documentBookmarkId: string,
    text: string,
    boxHighlight: HighlightBox,
    name?: string,
    addedByUser = true,
  ): Promise<TenderBookmark> {
    return $axios
      .post(`/bookmarks/${tenderBookmarkId}/${documentBookmarkId}/text/`, {
        document_bookmark_id: documentBookmarkId,
        text,
        box_highlight: boxHighlight,
        added_by_user: addedByUser,
        ...(name && { name }),
      })
      .then((response) => response.data);
  }

  async updateTextBookmark(
    tenderBookmarkId: string,
    documentBookmarkId: string,
    textBookmarkId: string,
    name: string,
  ): Promise<TenderBookmark> {
    return $axios
      .patch(
        `/bookmarks/${tenderBookmarkId}/${documentBookmarkId}/text/${textBookmarkId}/`,
        {
          document_bookmark_id: documentBookmarkId,
          name: name ?? "",
        },
      )
      .then((response) => response.data);
  }

  async deleteTextBookmark(
    tenderBookmarkId: string,
    documentBookmarkId: string,
    textBookmarkId: string,
  ) {
    return $axios
      .delete(
        `/bookmarks/${tenderBookmarkId}/${documentBookmarkId}/text/${textBookmarkId}/`,
      )
      .then((response) => response.data);
  }
}

export default new AssistantService();
