import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { CoreService } from "@/services";
import { useAuthStore } from "@/stores/auth";

const initialFlags = {
  flags_menu: false,
  feature_UIs_menu: false,
  deployment_info: false,
  show_dev_categories: false,
  gpt4_ui: false,
  import_template: false,
  new_tenders_layout: false,
  user_manual_popover: false,
  document_assistant: false,
  service_description_context: false,
  release_announcements: false,
  bubble_menu: false,
  tender_data: false,
};

export type FlagType = typeof initialFlags & Record<string, boolean>;

export const useFeatureStore = defineStore("feature", () => {
  const authStore = useAuthStore();

  // state
  const flags = ref<FlagType>(initialFlags);
  const defaultFlags = ref<FlagType>(initialFlags);

  async function resetFlags() {
    const remoteFlags = await CoreService.getFeatureFlags();

    defaultFlags.value = { ...initialFlags, ...remoteFlags };
    flags.value = { ...initialFlags, ...remoteFlags };
  }

  // saves flags to LocalStorage
  function saveFlags() {
    if (changedFlagsCount.value) {
      localStorage.setItem("flags", JSON.stringify(flags.value));
    } else {
      localStorage.removeItem("flags");
    }
  }

  // loads flags from backend and local storage
  async function initializeFlags() {
    await resetFlags();

    if (authStore.isGovRadarMember) {
      const unparsed = localStorage.getItem("flags");
      const localFlags = unparsed ? JSON.parse(unparsed) : {};

      overrideExistingFlags(localFlags);

      addEventListener("storage", (event) => {
        if (event.key === "flags") {
          if (event.newValue === null) {
            resetFlags();
          } else {
            overrideExistingFlags(JSON.parse(event.newValue));
          }
        }
      });
    }
  }

  const changedFlagsCount = computed(() => {
    return Object.entries(flags.value).filter(
      ([key, value]) => value !== defaultFlags.value[key],
    ).length;
  });

  function overrideExistingFlags(newFlags: FlagType) {
    Object.entries(newFlags).map(([key, value]) => {
      if (key in flags.value) {
        flags.value[key] = value;
      }
    });
  }

  return {
    flags,
    defaultFlags,
    changedFlagsCount,
    resetFlags,
    initializeFlags,
    saveFlags,
  };
});
