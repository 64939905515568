import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/beschaffungsvorhaben",
    name: "tender-data-list",
    component: () => import("../views/tenderData/TenderDataList.vue"),
  },
  {
    path: "/beschaffungsvorhaben/:tenderData",
    name: "tender-data-detail",
    component: () => import("../views/tenderData/TenderDataDetail.vue"),
  },
  // Legacy routes
  {
    path: "/leistungsbeschreibungen",
    name: "legacy-2-tender-data-list",
    redirect() {
      return {
        name: "tender-data-list",
      };
    },
  },
  {
    path: "/leistungsbeschreibungen/:tenderData",
    name: "legacy-2-tender-data-detail",
    redirect(to) {
      return {
        name: "tender-data-detail",
        params: {
          tenderData: to.params.tenderData,
        },
      };
    },
  },
  {
    path: "/leistungsverzeichnisse",
    name: "legacy-tender-data-list",
    redirect() {
      return {
        name: "tender-data-list",
      };
    },
  },
  {
    path: "/leistungsverzeichnisse/:tenderData",
    name: "legacy-tender-data-detail",
    redirect(to) {
      return {
        name: "tender-data-detail",
        params: {
          tenderData: to.params.tenderData,
        },
      };
    },
  },
  {
    path: "/leistungsverzeichnisse/:tenderData/details/:type/:id/:prefix",
    name: "legacy-tender-data-element-detail",
    redirect(to) {
      return {
        name: "tender-data-detail",
        params: {
          tenderData: to.params.tenderData,
        },
      };
    },
  },
];

export default routes;
